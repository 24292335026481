import React from "react";
import IconWww from "../Icons/platforms/IconWww";
import IconGithub from "../Icons/platforms/IconGithub";
import IconTwitter from "../Icons/platforms/IconTwitter";
import IconYoutube from "../Icons/platforms/IconYoutube";
import IconTwitch from "../Icons/platforms/IconTwitch";

function Person({ person }) {
  const { name, notes, website, github, twitter, youtube, twitch } = person;

  return (
    <div className="card w-full flex flex-col text-center overflow-hidden rounded bg-gray-100 justify-between text-black">
      <div className="bg-primary h-2 relative -top-1"></div>
      <h3 className="font-bold pt-4 pb-1">{name}</h3>
      <p className="text-sm py-4">{notes}</p>
      <div className="social text-center flex flex-row justify-center">
        {website && (
          <a href={website} className="p-1">
            <IconWww width="25" className="" />
          </a>
        )}
        {github && (
          <a href={github} className="p-1">
            <IconGithub width="25" />
          </a>
        )}
        {twitter && (
          <a href={twitter} className="p-1">
            <IconTwitter width="25" />
          </a>
        )}
        {youtube && (
          <a href={youtube} className="p-1">
            <IconYoutube width="25" />
          </a>
        )}
        {twitch && (
          <a href={twitch} className="p-1">
            <IconTwitch width="25" />
          </a>
        )}
      </div>
    </div>
  );
}

export default Person;
