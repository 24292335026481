import React from "react";
import LogoSlack from "../../../static/logos/slack.png";
import LogoDiscord from "../../../static/logos/discord.png";
import LogoReddit from "../../../static/logos/reddit.png";
import IconIRC from "../Icons/platforms/IconIRC";

function Help({ help, platform }) {
  const { url } = help;
  let platformColor;
  let platformIcon;
  let platformHover;
  let platformBorder;
  if (platform === "discord") {
    platformIcon = <img src={LogoDiscord} alt="Discord Logo" className="w-full" />;
    platformColor = "bg-discord-default";
    platformHover = "hover:bg-discord-hover";
    platformBorder = "border-discord-hover"
  } else if (platform === "slack") {
    platformIcon = <img src={LogoSlack} alt="Slack Logo" className="w-full" />;
    platformColor = "bg-slack-default";
    platformHover = "hover:bg-slack-hover";
    platformBorder = "border-slack-hover"
  } else if (platform === "reddit") {
    platformIcon = <img src={LogoReddit} alt="Reddit Logo" className="w-full" />;
    platformColor = "bg-reddit-default";
    platformHover = "hover:bg-reddit-hover";
    platformBorder = "border-reddit-hover"
  } else {
    platformIcon = <div className="bg-white rounded-full font-bold">{help.title[0]}</div>;
    platformColor = "bg-slate-500";
    platformHover = "hover:bg-slate-600";
    platformBorder = "border-slate-600"
  }

  return (
    <a
      key={help.title}
      href={url}
      className={`w-full flex flex-col pt-2 justify-between text-center ${platformColor} ${platformHover}`}
    >
      <div className="w-1/5 text-center p-1 self-center">{platformIcon}</div>
      <p className="flex items-center justify-center h-full w-full font-bold py-6 px-2 text-white shadow-md">{help.title}</p>
      <div className={`p-2 capitalize font-bold border-t ${platformBorder} text-white`}>
        {platform}
      </div>
    </a>
  );
}

export default Help;
