import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
const _ = require("lodash");
import Layout from "../layout";
import Person from "../components/Language/Person";
import Help from "../components/Language/Help";
import LanguageCard from "../components/Language/LanguageCard";
import RatingCard from "../components/Language/RatingCard";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./language.css";

export default function LanguageTemplate({ data, pageContext }) {
  const { tagList } = pageContext;
  const [tag, setTag] = useState("all");
  const lang = data.languagesJson;

  const filterPersonElement = () => {
    if (tag === "all") {
      return lang.people.map((person) => <Person person={person} />);
    } else {
      const res = lang.people.filter((person) => {
        return person.tags.includes(tag);
      });
      return res.map((person) => <Person person={person} />);
    }
  };

  const renderSlack = () => {
    if (lang.get_help.slack?.length >= 0) {
      return lang.get_help.slack.map((help) =>
        <Help help={help} platform="slack" />
      )
    }
  };

  const renderDiscord = () => {
    if (lang.get_help.discord?.length >= 0) {
      return lang.get_help.discord.map((help) => 
        <Help help={help} platform="discord" />
      )
    }
  };

  const renderReddit = () => {
    if (lang.get_help.reddit?.length >= 0) {
      return lang.get_help.reddit.map((help) =>
        <Help help={help} platform="reddit" />
      )
    }
  };

  const renderForums = () => {
    if (lang.get_help.forums?.length >= 0) {
      return lang.get_help.forums.map((help) => 
        <Help help={help} platform="forums" />
      )
    }
  };

  const renderIRC = () => {
    if (lang.get_help.irc?.length >= 0) {
      return lang.get_help.irc.map((help) => 
        <Help help={help} platform="irc" />
      )
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>{`${lang.name} | ${config.siteTitle}`}</title>
      </Helmet>
      <div className="bg-zinc-200">
        <article className="flex flex-col justify-between">
          <div className="flex flex-col md:flex-row">
            {/* <div className="w-full md:w-1/4 flex flex-col pb-8">
              <div className="flex flex-col mt-6">
                <h3 className="font-heavy font-bold pl-2 pb-2">Quick Navigation</h3>
                <a
                  className="py-2 pl-2 border-b hover:bg-gray-100"
                  href="#language-overview"
                >
                  Language Overview
                </a>
                <a
                  className="py-2 pl-2 border-b hover:bg-gray-100"
                  href="#learnability"
                >
                  Learnability
                </a>
                <a className="py-2 pl-2 border-b hover:bg-gray-100" href="#career">
                  Career
                </a>
                <a
                  className="py-2 pl-2 border-b hover:bg-gray-100"
                  href="#community"
                >
                  Community
                </a>
                <a
                  className="py-2 pl-2 border-b hover:bg-gray-100"
                  href="#learning-resources"
                >
                  Learning Resources
                </a>
              </div>
            </div> */}

            <div className="w-full">
              <section id="language-overview" className="pb-14 border-b border-b-gray-600 md:p-20 bg-white">
                <div className="container mx-auto md:px-14">
                  <div className="flex flex-col md:flex-row items-center">
                    <div className="w-full md:w-3/4 flex flex-col">
                      <h2 className="text-3xl pb-6 pt-8 md:pt-0">Language Overview</h2>
                      <p className="prose prose-slate prose-xl"> {lang.description} </p>
                      <a className="capitalize font-bold m-2 py-2 text-primary  hover:text-primary-hover contents" href={lang.links.language} target="_blank"> Visit the Official Site </a>
                    </div>
                    <div className="w-full pt-6 md:pt-0 md:w-1/4">
                      <LanguageCard language={lang} />
                    </div>
                    
                  </div> 
                </div>
              </section>

              <section id="learnability" className="p-6 md:p-20 bg-gradient-to-r from-slate-800 to-slate-700">
                <div className="container py-6 md:py-8">
                  <h2 className="text-3xl text-white pb-2">Learnability</h2>
                  <p className="prose prose-slate prose-xl pb-6 text-gray-200">
                    How learnable is a given language? It depends on a variety of
                    factors, including how difficult it is to learn the syntax or
                    find reliable resources when you get stuck.
                  </p>
                  <p className="prose prose-slate prose-xl pb-6 text-gray-200">
                    Ready to start learning? We suggest using visiting <a href={lang.links.exercism} className="text-white" target="_blank">exercism.org</a> to start your journey
                  </p>
                  <div className="cards grid gap-4 grid-cols-2 md:grid-cols-4">
                    <RatingCard 
                      tag={lang.syntax.value}
                      slug="syntax"
                      header="Syntax"
                      text="The rules and structure of the language"
                    />
                    <RatingCard 
                      tag={lang.ecosystem.value}
                      slug="ecosystem"
                      header="Ecosystem"
                      text="The individuals and projects using it"
                    />
                    <RatingCard 
                      tag={lang.barrier_to_entry.value}
                      slug="barrier-to-entry"
                      header="Barrier to Entry"
                      text="The difficulty of getting started"
                    />
                    <RatingCard 
                      tag={lang.getting_help.value}
                      slug="getting-help"
                      header="Getting help"
                      text="The ease of finding help from the community"
                    />
                  </div>
                </div>
              </section>

              <section id="career" className="p-4 md:p-16 border-b">
                <div className="container py-6 md:py-8">
                  <h2 className="text-3xl pb-2">Career</h2>
                  <p className="prose prose-slate prose-xl pb-6">
                    What is the demand and pay for people writing in this language?
                    What is the reality of their day-to-day? Everything
                    career-related is explored here.
                  </p>
                  <div className="cards grid gap-4 grid-cols-2 md:grid-cols-4">
                    <RatingCard 
                      tag={lang.demand.value}
                      slug="demand"
                      header="Demand"
                      text="How many jobs are available"
                    />
                    <RatingCard 
                      tag={lang.competition.value}
                      slug="competition"
                      header="Competition"
                      text="How many developers are available"
                    />
                  </div>
                </div>
              </section>

              <section id="community" className="p-4 md:p-16 pb-28  bg-gradient-to-r from-slate-800 to-slate-700 text-white">
                <div className="container py-6">
                  <h2 className="text-3xl pb-2">Community</h2>
                  <p className="prose prose-slate prose-xl pb-6 text-gray-200">
                    Ways to interact with the community, from following influential
                    leaders on Twitter to meeting people in virtual forums or at
                    conferences.
                  </p>
                  <div id="leaders">
                    <div className="py-6 flex flex-row flex-wrap items-center">
                      <span className="text-lg font-bold mr-4">Filter by:</span>
                      <a
                        className="cursor-pointer capitalize font-bold m-2 text-center inline-flex justify-center items-center flex-col px-6 py-2 mb-2 rounded-md bg-slate-800 hover:bg-gray-100 text-white hover:text-slate-900"
                        onClick={() => setTag("all")}
                      >
                        {" "}
                        all{" "}
                      </a>
                      
                      {tagList.length > 0 &&
                        tagList.map((tagFromList) => (
                          <a
                            className="cursor-pointer capitalize font-bold m-2 text-center inline-flex justify-center items-center flex-col px-6 py-2 rounded-md  bg-slate-800 hover:bg-gray-100 text-white hover:text-slate-900"
                            onClick={() => setTag(tagFromList)}
                          >
                            {" "}
                            {tagFromList}{" "}
                          </a>
                        ))
                      }
                      
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 md:gap-8 gap-4">
                      {filterPersonElement()}
                    </div>
                  </div>
                </div>
              </section>

              <section id="learning-resources" className="p-4 md:p-16 pb-28">
                <div className="container py-6 md:py-12">
                  <h2 className="text-3xl pb-2">Learning Resources</h2>
                  <p className="prose prose-slate prose-xl pb-6">
                    Find resources to help you learn--from documentation and
                    tutorials to forums where you can interact with other
                    developers.
                  </p>

                  <div className="grid grid-cols-2 grid-auto-rows md:grid-cols-4 gap-4">
                    {renderSlack()}
                    {renderDiscord()}
                    {renderReddit()}
                    {renderForums()}
                    {renderIRC()}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query LanguageBySlug($slug: String!) {
    languagesJson(slug: { eq: $slug }) {
      name
      description
      used_for
      slug
      links {
        language
        exercism
      }
      syntax {
        value
      }
      ecosystem {
        value
      }
      pay {
        value
      }
      demand {
        value
      }
      competition {
        value
      }
      barrier_to_entry {
        value
        meta {
          explanation
        }
      }
      getting_help {
        value
      }
      get_help {
        slack {
          title
          url
        }
        reddit {
          title
          url
        }
        discord {
          title
          url
        }
        forums {
          title
          url
        }
        irc {
          title
          url
        }

      }
      people {
        name
        notes
        website
        github
        twitter
        youtube
        twitch
        tags
      }
    }
  }
`;
