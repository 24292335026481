import React from "react";
import { Link } from "gatsby";
import IconCode from "../Icons/IconCode";
import IconEcosystem from "../Icons/IconEcosystem";
import IconBarrier from "../Icons/IconBarrier";
import IconHelp from "../Icons/IconHelp";
import IconDemand from "../Icons/IconDemand";
import IconCompetition from "../Icons/IconCompetition";
const _ = require("lodash");

function RatingCard({ tag, slug, header, text }) {

  const renderIcon = (slug) => {
    console.log(slug)
    if (slug === "syntax") {
      return <IconCode fill="#fff" />
    } else if (slug === "ecosystem") {
      return <IconEcosystem fill="#fff" />
    } else if (slug === "barrier-to-entry") {
      return <IconBarrier fill="#fff" />
    } else if (slug === "getting-help") {
      return <IconHelp fill="#fff" />
    } else if (slug === "demand") {
      return <IconDemand fill="#fff" />
    } else if (slug === "competition") {
      return <IconCompetition fill="#fff" />
    }
  }

  return (
    <Link
      className="flex flex-col relative overflow-hidden justify-between w-full bg-gray-100 hover:bg-white drop-shadow-sm rounded-md text-left text-black hover:drop-shadow-lg"
      key={tag}
      style={{ textDecoration: "none" }}
      to={`/${slug}/${_.kebabCase(tag)}`}
    >
      <div className="bg-primary h-2 relative -top-1"></div>
      <div className="definition p-4">
        <div className="rounded-full bg-primary p-2 inline-block">
          {renderIcon(slug)}
        </div>
        <p className="tag font-bold text-lg capitalize text-gray-900">{header}</p>
        <p className="italic text-sm text-gray-600">
          {text}
        </p>
      </div>
      <div className="">
        <p className="uppercase text-xl font-bold p-4 text-black">
          {tag}
        </p>
      </div>
    </Link>
  );
}

export default RatingCard;